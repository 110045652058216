/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('smsService', function (http) {
    let url = config.apiURL + 'sms/', errors = [422, 500];
    function errorHandler(err) {
        if (err && err.data && err.data.message) {
            return Promise.reject(err.data.message);
        }
        return Promise.reject(err);
    }
    return {
        get: (by = {}, page = 1, pageSize) => {
            let _url = url, params = [];
            if (by) {
                if (by.type) {
                    params.push('filter[type]=' + by.type);
                }
                if (by.id) {
                    _url += by.id;
                } else { // options only for many results
                    for (let i in by) {
                        if (['id', 'type'].indexOf(i) < 0) {
                            params.push('filter[' + i + ']=' + by[i]);
                        }
                    }
                    if (page > 1) {
                        params.push('page=' + parseInt(page));
                    }
                    if (pageSize) {
                        params.push('pageSize=' + parseInt(pageSize));
                    }
                }
            }
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    }
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        send: (data) => {
            let _url = url + 'send';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });

        },
        delete: (item) => {
            return http.delete(url + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        }
    };
});
